import { useRef, useState } from 'react';
import './Contact.css'

const Contact = () => {
  const [submitted, setSubmitted] = useState(false)

  const contactFirstName = useRef();
  const contactLastName = useRef();
  const contactEmail = useRef();
  const contactAddress = useRef();
  const contactPhone = useRef();
  const contactComments = useRef();
  const contactHearABoutOther = useRef();

  const submitForm = (e) => {
    e.preventDefault()

    fetch('https://docs.google.com/forms/u/0/d/e/1FAIpQLSdZSwOkzq5WJRmAyPbeNHuZwYmcoMWSnQ3aVwYDJyz6t60Y9w/formResponse', {
      method: "POST",
      mode: "no-cors",
      header: {
        'Content-Type': 'application/json'
      },
      body: getInputData()
    })
    .then(data => {
      console.log(data)
    })
    .catch(err => console.error(err))
  }

  const getInputData = () => {
    let dataToPost = new FormData()

    dataToPost.append('emailAddress', contactEmail.current.value)
    dataToPost.append('entry.2005620554', contactFirstName.current.value)
    dataToPost.append('entry.1692016042', contactLastName.current.value)
    dataToPost.append('entry.1065046570', contactPhone.current.value)
    dataToPost.append('entry.1045781291', contactAddress.current.value)
    dataToPost.append('entry.667913936', contactComments.current.value)
    dataToPost.append('entry.1795484362', contactHearABoutOther.current.value)
    handleFields()

    return dataToPost;
  }

  const handleFields = () => {
    setSubmitted(true);

    contactFirstName.current.value = '';
    contactLastName.current.value = '';
    contactEmail.current.value = '';
    contactPhone.current.value = '';
    contactAddress.current.value = '';
    contactComments.current.value = '';
    contactHearABoutOther.current.value = '';
    setTimeout(() => { setSubmitted(false); }, 5000);
  }

  return (
		<div className="contact-wrap">
			<div className="form-container">
				<div className="hello-sent-wrap">
					{!submitted ? 
						<h1 className="contact-head"> How can we help? </h1> :
						<h1 className="contact-head"> SENT! </h1>  
					}
				</div>
				<form
					className="contact-form"
					action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSdZSwOkzq5WJRmAyPbeNHuZwYmcoMWSnQ3aVwYDJyz6t60Y9w/formResponse"
					method="POST"
          onSubmit={(e) => {
            submitForm(e);
          }}>
					<label className="form-field label" htmlFor="name-input" name="name">
						Name
					</label>
            <div className='name-fields'>
					<input
						className="form-name form-field input"
						htmlFor="name-input"
						type="text"
						ref={contactFirstName}
						name="entry.2005620554"
						placeholder="First"
						required></input>
					<input
						className="form-name form-field input"
						htmlFor="name-input"
						type="text"
						ref={contactLastName}
						name="entry.1692016042"
						placeholder="Last"
						required></input>
            </div>
					<label
						className="form-field label"
						htmlFor="email-input"
						name="email">
						E-Mail
					</label>
					<input
						className="form-email form-field input"
						htmlFor="email-input"
						type="email"
						ref={contactEmail}
						// name="entry.403214365"
						name="emailAddress"
						placeholder="E-Mail"
						required></input>
					<label
						className="form-field label"
						htmlFor="phone-input"
						name="phone">
						Phone Number
					</label>
					<input
						className="form-phone form-field input"
						htmlFor="phone-input"
						type="text"
						ref={contactPhone}
						name="entry.1065046570"
						placeholder="Phone Number"
            pattern="[0-9]{10}"
            maxLength="10"
						required="required"></input>
					<label
						className="form-field label"
						htmlFor="address-input"
						name="address">
						Address
					</label>
					<input
						className="form-address form-field input"
						htmlFor="address-input"
						type="text"
						ref={contactAddress}
						name="entry.1045781291"
						placeholder="Address where work is needed"
						required></input>
					<label className="form-field label" htmlFor="comments-input">
						Comments
					</label>
          <input
						className="form-comments form-field input"
						htmlFor="comments-input"
						type="text"
						ref={contactComments}
						name="entry.667913936"
						placeholder="Comments"
						required></input>
            <label className="form-field label" htmlFor="hear-about-us-input">
						How Did You Hear ABout Us?
					  </label>
            <input
						className="form-comments form-field input"
						htmlFor=""
						type="text"
						ref={contactHearABoutOther}
						name="entry.1795484362"
						placeholder="Google, Yelp, Instagram, Facebook, a Friend, etc.."
						required></input>
            
              {!submitted ? <button className="submit-button" type="submit">Submit
            </button>: <button className="submit-button" type="submit">SENT!
            </button>}
				</form>
			</div>
      <div className='direct-contact'>
        <h4>Reach out directly by:</h4>
      <a className='contact-phone' href='tel: 2158201481'>Phone: (215) 820-1481 </a>
      <a className='contact-email'href="mailto: jeremy@smarterhomeintegrations.com">E-Mail: info@smarterhomeintegrations.com</a>
      </div>
		</div>
	);

}

export default Contact;