import './Home.css'
import speedIcon from '../../assets/speed_icon_24dp.svg'
import proIcon from '../../assets/pro_icon_24dp.svg'
import shieldIcon from '../../assets/shield_icon_24dp.svg'
import globeIcon from '../../assets/globe_icon.svg'


const Home = () => {
  return (
    <div 
     className='home-container'>
      {/* <img className='home-background' src={philly} alt='scenic view of Philadelphia'></img> */}
      <div className='values-container'>
        <h1 className='home-values'>Secure, Trusted, Seamless </h1>
        <h5 className='home-tag'>Trusted locally to connect you globally.</h5>
      </div>
      <article className='home-welcome'>
        <h2>Welcome to Smarter Home Integrations LLC</h2>
        <span className='welcome-dash'></span>
        <p className='welcome-intro'>Smarter Home Integrations LLC is a veteran owned and operated smart home improvement company that is focused on strengthening your internet connection, enhancing your existing network, as well as installing, & configuring all of your smart devices.</p>
      </article>
      <div className='skills-container'>
        <div className='skill-box skill-1'>
          <img className='skill-image skill-1' src={shieldIcon} alt='Shield'></img>
          <h3 className='skill-name'>Reliable</h3>
          <p className='skill-body'>We respect your time and responsibilites, so we're committed to simplifying the customer experience.</p>
        </div>
        <div className='skill-box skill-2'>
          <img className='skill-image skill-image-2' src={speedIcon} alt='speedometer'></img>
          <h3 className='skill-name'>Fast</h3>
          <p className='skill-body'>Set up an evaluation and we'll work around your schedule to have you set up in no time.</p>
        </div>
        <div className='skill-box skill-3'>
          <img className='skill-image skill-image-3' src={proIcon} alt='off to work'></img>
          <h3 className='skill-name'>Professional</h3>
          <p className='skill-body'>Trusted in homes and offices throughout Philadelphia, your remote office and learning spaces won't be interrupted.</p>
        </div>
        <div className='skill-box skill-4'>
          <img className='skill-image skill-image-4' src={globeIcon} alt='globe metaphor'></img>
          <h3 className='skill-name'>Experienced</h3>
          <p className='skill-body'>With over 5 years in home improvement and Information Technology, we provide clean installs that keeps working.</p>
        </div>
      </div>
    </div>
  )
}

export default Home;
