import './Services.css'
import wifi_icon from '../../assets/wifi_icon.svg'
import home_icon from '../../assets/home_icon.svg'
import lock_icon from '../../assets/lock.svg'
import footprint_icon from '../../assets/footprint_icon.svg'

const Services = () => {
  return (
    <section className='services-container'>
      <div className='services'>
      <h1 className='services-title'>Services:</h1>
      <h2 className='service'>Networking:</h2>
      <ul className='networking list'>
        <li>Wired and Wireless Network Extensions and Upgrades</li>
      </ul>
      <h2 className='service'>Network and Computer Maintenance:</h2>
      <ul className='network-computer list'>
        <li>Network Infrastructure Maintenance</li>
        <li>Repairs &amp; Upgrades</li>
      </ul>
      <h2 className='service'>Technical Renovations:</h2>
      <ul className='tech-renovations list'>
        <li>Mounting TVs</li>
        <li>Home theater installs</li>
        <li>Cable management</li>
        <li>Device organization &amp; relocation</li>
      </ul>
      <h2 className='service'>Home office solutions:</h2>
      <ul className='home-office list'>
        <li>Increase WiFi speeds and connectivity</li>
        <li>Extend WiFi distances</li>
      </ul>
      <h2 className='service'>Smart Home Upgrades: </h2>
      <ul className='smart-home list'>
        <li>NEST, RING, and other smart home install &amp; setups</li>
        <li>wired and wireless security installs</li>
      </ul>
      <h2 className='service'>Consolidation &amp; Organization of Devices:</h2>
      <ul className='consolidation list'>
        <li>Relocate previous &amp; new devices to centralized area</li>
      </ul>
      </div>
      <div className='results-container'>
        <div className='result-box result-1'>
          <img className='result-image result-1' src={wifi_icon} alt='wifi signal'></img>
          <h3 className='result-name'>Solid connectivity</h3>
          <p className='result-body'>Extending your wired and wireless networks results in overall better connection</p>
        </div>
        <div className='result-box result-2'>
          <img className='result-image skill-image-2' src={home_icon} alt='home'></img>
          <h3 className='result-name'>seamless roaming</h3>
          <p className='result-body'>Adding new access points and proper configuration results in seamless roaming, creating a "mesh" network</p>
        </div>
        <div className='result-box result-3'>
          <img className='result-image result-image-4' src={lock_icon} alt='lock'></img>
          <h3 className='result-name'>Increased Security</h3>
          <p className='result-body'>Proper device location and configurations result in greater security</p>
        </div>
        <div className='result-box result-4'>
          <img className='result-image skill-image-3' src={footprint_icon} alt='skill-name '></img>
          <h3 className='result-name'>Broad Wireless Footprint</h3>
          <p className='result-body'>Turn your entire property into a HOTSPOT, eliminating weak signal areas</p>
        </div>
      </div>
    </section>
  )
}

export default Services;