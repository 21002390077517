import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import './Nav.css'
import SMI_Logo from '../../assets/SMI_Logo.svg'
import instaIcon from '../../assets/insta_icon.svg'
import facebookIcon from '../../assets/facebook_icon.svg'
import yelpIcon from '../../assets/yelp_icon.svg'

const Nav = () => {
  const [navActive, setNavActive] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    handleScroll()
  }, [navActive])

  useEffect(() => {
    window.removeEventListener('resize', handleWindowSizeChange);
  }, [windowWidth])

  const handleScroll = () => {
    !navActive ? enableScroll() : disableScroll();
  }

  const handleWindowSizeChange = () => {
    setWindowWidth(window.innerWidth);
  };

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop; 
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    window.onscroll = () => { 
      window.scrollTo(scrollLeft, scrollTop); 
      }; 
  }

  const enableScroll = () => {
    document.body.style.overflow = "auto";
    window.onscroll = () => {}
  }

  const mobileNav = () => {
		return (
			<div className="mobile-nav-container">
				<div
					className={navActive ? "burger-container open" : "burger-container"}
					onClick={() => setNavActive(!navActive)}>
					<span className="patty top"></span>
					<span className="patty mid"></span>
					<span className="patty bottom"></span>
				</div>
				<div className={navActive ? "nav-open" : "hidden"}>
					<ul className="nav-links">
						<NavLink
							exact={true}
							to="/"
							activeClassName="nav-selected"
							onClick={() => setNavActive(false)}>
							<li>Home</li>
						</NavLink>
            <NavLink
            to='/services'
            activeClassName='nav-selected'
            onClick={() => setNavActive(false)}>
						<li>Services</li>
						</NavLink>
						<NavLink
							to="/contact"
							activeClassName="nav-selected"
							onClick={() => setNavActive(false)}>
							<li>Contact</li>
						</NavLink>
						{/* <NavLink
            to='/testimonials'
            activeClassName='nav-selected'> */}
						{/* <li className="not-active">Testimonials</li> */}
						{/* </NavLink> */}
						{/* <NavLink
            to='/aboutus'
            activeClassName='nav-selected'> */}
						<li className="not-active">About Us</li>
						{/* </NavLink> */}
					</ul>
          <div className="nav-socials">
					<a
						href="https://www.instagram.com/smarterhomeintegrations/?utm_medium=copy_link"
						target="_blank"
						rel="noreferrer">
						<img className="social-icon" src={instaIcon} alt='Instagram'/>
					</a>
					<a
						href="https://www.facebook.com/SmarterHomeIntegrations/"
						target="_blank"
						rel="noreferrer">
						<img className="social-icon" src={facebookIcon} alt='Facebook'/>
					</a>
          <a
							href="https://www.yelp.com/biz/smarter-home-integrations-philadelphia"
							target="_blank"
							rel="noreferrer">
							<img className="social-icon" src={yelpIcon} alt='Yelp'/>
						</a>
				</div>
				</div>
        <NavLink
							exact={true}
							to="/">
				<img
					className="nav-logo"
					src={SMI_Logo}
					alt="Smarter Home Integrations logo"
				/>
        </NavLink>
			</div>
		);
	};

  const deskTopNav = () => {
		return (
			<div className="desktop-nav-container">
				<NavLink exact={true} to="/">
					<img
						className="nav-logo"
						src={SMI_Logo}
						alt="Smarter Home Integrations logo"
						onClick={() => window.location.reload()}
					/>
				</NavLink>
				<div className="nav-open">
					<ul className="nav-links">
						<NavLink exact={true} to="/" activeClassName="nav-selected">
							<li>Home</li>
						</NavLink>
						<NavLink
							to="/services"
							activeClassName="nav-selected"
							>
							<li>Services</li>
						</NavLink>
						<NavLink to="/contact" activeClassName="nav-selected">
							<li>Contact</li>
						</NavLink>
						{/* <NavLink
            to='/testimonials'
            activeClassName='nav-selected'> */}
						{/* <li className="not-active">Testimonials</li> */}
						{/* </NavLink> */}
						{/* <NavLink
            to='/aboutus'
            activeClassName='nav-selected'> */}
						<li className="not-active">About Us</li>
						{/* </NavLink> */}
					</ul>
					<div className="nav-socials">
						<a
							href="https://www.instagram.com/smarterhomeintegrations/?utm_medium=copy_link"
							target="_blank"
							rel="noreferrer">
							<img className="social-icon" src={instaIcon} alt='Instagram'/>
						</a>
						<a
							href="https://www.facebook.com/SmarterHomeIntegrations/"
							target="_blank"
							rel="noreferrer">
							<img className="social-icon" src={facebookIcon} alt='Facebook'/>
						</a>
            <a
							href="https://www.yelp.com/biz/smarter-home-integrations-philadelphia"
							target="_blank"
							rel="noreferrer">
							<img className="social-icon" src={yelpIcon} alt='Yelp'/>
						</a>
					</div>
				</div>
			</div>
		);
	};

  return (
    windowWidth <= 768 ? mobileNav() : deskTopNav()
  )

}

export default Nav;