import { Switch, Route, Redirect } from "react-router-dom";
import './App.css';
import Nav from '../Nav/Nav'
import Home from '../Home/Home'
import Contact from '../Contact/Contact'
import Services from '../Services/Services'

function App() {
  return (
    <main className="site-container">
      <Nav />
      <Switch>
        <Route exact path= '/'
          render={() => (
            <Home />
          )}
        />
        <Route exact path= '/services'
          render={() => (
            <Services />
          )}
        />
        <Route exact path= '/contact'
          render={() => (
            <Contact />
          )}
        />
      </Switch>
      <footer>
        <p className='footer'>© 2021 Smarter Home Integrations LLC</p>
      </footer>
    </main>
  );
}

export default App;
